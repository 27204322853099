@import '../../../../scss/colors.scss';

footer {
  padding-top: 25px;
  padding-bottom: 0;
  color: #757d82;
  background-color: white;
  box-shadow: inset 0 3px 6px #00000014;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    .socials {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      a {
        //color: white;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;

        &:hover {
          svg {
            cursor: pointer;
            transition: transform 200ms;
            transform: scale(1.2);

            path {
              fill: $orange;
            }
          }
        }
      }
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      a {
        text-decoration: underline;
        color: #757d82;
        padding: 0 15px;
        &:not(:last-child) {
          border-right: 1px solid #757d82;
        }
      }
    }
  }

  .info {
    margin-top: 25px;
    width: 100%;
    height: 50px;
    background-color: #262626;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    & div {
      padding: 0 20px;
      a {
        color: #fff;
      }
    }
  }

  //media for screens less 1120px

  @media (max-width: 1120px) {
    box-shadow: unset;

    .socials {
      gap: 30px !important;
    }

    .links {
      a {
        padding: 0 10px !important;
      }
    }

    .addressWrapper {
      width: 80%;
      text-align: center;
    }
  }

  //media for screens less 575px

  @media (max-width: 575px) {
    box-shadow: unset;
  }
}
