@import './src/scss/colors.scss';

.news-page {
  .articles-list {
    padding: 60px 32px 0 32px;
    background-color: $main-gray;

    .article-card {
      height: 450px;
      background-color: white;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        .content {
          background-color: $orange;
          color: white;
        }
      }

      .img-block {
        height: 270px;
        padding: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7.5px;
        }
      }

      .content {
        padding: 25px 35px;
        color: $main-black;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        height: 100%;

        .title {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 15px;
        }

        .text {
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }

  //media for screens less 575px

  @media (max-width: 575px) {
    .dashboard-top-block {
      justify-content: center;
      text-align: center;
      font-size: 36px;
      padding: 120px 16px 60px 16px;
    }

    .dashboard-breadcrumbs {
      padding: 32px 16px;
    }

    .articles-list {
      padding: 32px 16px;

      &-container {
        padding: unset;

        &-grid {
          width: 100%;
          margin: unset;
          padding: unset;

          & > div {
            padding: unset;
          }

          & > div:not(:last-child) {
            margin: 0 0 20px 0;
          }

          .article-card {
            height: unset;
            border-radius: 15px 15px 7.5px 7.5px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

            .img-block {
              width: 100%;
              height: unset;
            }

            .content {
              padding: 32px 16px;

              .clamp-lines {
                text-align: justify;
                max-height: 115px;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
