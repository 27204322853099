@import './src/scss/colors.scss';

.home-page {
  background-color: white;
  z-index: 1;
  .notification-container {
    width: 100%;
    top: 80px;
    .notification-warning {
      background-color: $orange;
    }
    .notification-success {
      background-color: #00c343;
    }
    .notification-error {
      background-color: #de2525;
    }
  }

  .top-background-block {
    @keyframes na {
      from {
        opacity: 0.1;
      }
      to {
        opacity: 1;
      }
    }
    height: 100vh;
    background-image: url('./imgs/top-back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    animation-name: na;
    animation-duration: 1.2s;

    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: fill;
    }

    .black-back {
      background-color: rgba(38, 38, 38, 0.2);
      height: 100%;

      .arrow-scroll-down {
        color: $orange;
      }

      .MuiContainer-root {
        height: 100%;

        .MuiGrid-root {
          height: 100%;
          position: relative;
        }

        .central-block {
          justify-self: center;
          text-align: center;

          .title {
            color: white;
            font-size: 60px;
            line-height: 72px;
            margin-bottom: 25px;
            font-weight: bold;
          }

          .search-input {
            width: 900px;
            height: 80px;
            background-color: rgba(255, 255, 255, 0.3);
            padding: 15px;
            backdrop-filter: blur(20px);
            border-radius: 50px;
            position: relative;

            .input-inner {
              display: flex;
              align-items: center;
              background-color: white;
              height: 100%;
              border-radius: 50px;
              overflow: hidden;
              padding: 5px 25px;

              input {
                border: 0;
                width: 100%;
                outline: none;
                font-size: 20px;
                color: $main-black;
                height: 30px;

                &::placeholder {
                  color: #8c8c8c;
                  text-align: center;
                }
              }

              img {
                height: 20px;
                width: 20px;
              }
            }

            .search-autocomplete-list {
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 12px;
              overflow: hidden;
              margin-top: 10px;

              .item {
                padding: 8px 10px;
                background-color: white;
                width: 865px;
                text-align: left;
                cursor: pointer;

                &:not(.loading):hover {
                  background-color: $orange;
                  color: white;
                }

                &:not(:last-child) {
                  border-bottom: 1px solid silver;
                }
              }
            }
          }

          .under-title {
            color: white;
            font-size: 20px;
            line-height: 19px;
            margin-bottom: 35px;
            font-weight: 500;
          }

          button {
            margin-top: 30px;
            width: 150px;
            height: 50px;
            border-radius: 25px;
            font-size: 20px;
          }
        }

        .explore-button {
          margin-right: -90vw;
          justify-self: flex-end;
          padding-bottom: 70px;

          button {
            border: 0;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(38, 38, 38, 0.75);
            cursor: pointer;

            img {
              margin-bottom: -3px;
            }
          }
        }
      }
    }
  }

  //media for screens less 1120px

  @media (max-width: 1120px) {
    .top-background-block {
      .black-back {
        .MuiContainer-root {
          .central-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: -80px;

            .title {
              width: 95%;
              font-size: 46px !important;
              line-height: 50px !important;
              margin-bottom: 16px !important;
            }

            .under-title {
              margin-bottom: 16px !important;
            }

            .search-input {
              width: 70%;
              height: 60px !important;
              padding: 10px !important;

              input {
                font-size: 15px !important;
                padding: 0 15px 0 0 !important;

                ::placeholder {
                  font-size: 15px;
                }
              }

              .search-autocomplete-list {
                max-height: 300px;
                overflow: auto !important;
                overflow-y: auto;
                width: 97%;

                .item {
                  width: unset !important;
                  padding: 12px !important;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }

    .btn-orange {
      height: 45px !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      margin-top: 12px !important;
    }

    .explore-button {
      margin-right: -80vw !important;
      padding-bottom: 15px !important;
    }
  }

  @media (max-width: 768px) {
    .top-background-block {
      .black-back {
        .MuiContainer-root {
          .central-block {
            .search-input {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

//media for screens less than 700px

@media (max-width: 700px) {
  .home-page {
    flex-direction: column;
  }
}
