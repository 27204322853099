@import '../../../scss/fonts.scss';
@import '../../../scss/utils.scss';
@import '../../../scss/colors.scss';

.btn-apply-to-all {
  background-color: $orange;
  color: #FFFFFF;
  border: 1px solid $orange;      
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  height: 50px;
  border-radius: 25px;
  padding: 0 20px;
  width: 300px;
  font-weight: bold;
  & > svg {
    margin-left: 10px;
    transform: rotate(-90deg);
  }
}
