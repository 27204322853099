@import '../../../scss/fonts.scss';
@import '../../../scss/utils.scss';
@import '../../../scss/colors.scss';

.list-item-with-details {
  transition: max-height 1s;
  max-height: 2000px;
  &.details-opened {
    max-height: 6000px;
  }
  &:not(:first-child) {
    margin-top: 30px;
  }

  .list-item-container {
    width: 100%;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: 205px 1fr 325px;
    @media (max-width: 960px) { 
      grid-template-columns: 1fr;
      grid-template-areas:
        'img'
        'main'
        'price'
        'payType'
        'moreInfo';
    }
    border-radius: 7.5px;
    box-shadow: 0 3px 10px #00000026;

    .diy-partner-info {
      display: flex;
      .img {
        display: flex;
        margin: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 7.5px;
        width: 100px;
        height: 100px;
        @media (max-width: 960px) { 
          width: 100%;
          background-size: cover;
          background-position: center center;
        }
        object-fit: cover;
        aspect-ratio: 1 / 1;
        position: relative;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        display: flex;
        margin: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 7.5px;
        width: 180px;
        height: 180px;
        @media (max-width: 960px) { 
          width: 100%;
          background-size: cover;
          background-position: center center;
        }
        object-fit: cover;
        aspect-ratio: 1 / 1;
        position: relative;
        button {
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          width: 120px;
          height: 30px;
          line-height: 30px;
          border: 0px;
          background-color: #757d82CC;
          border-radius: 0px 0px 7.5px 7.5px;
          color: #FFFFFF;
          z-index: 10;
        }
      }
    }

    .main-content {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 20px;
        margin: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #c9c9c9;
        margin-bottom: 10px;
      }

      .tags {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media (max-width: 655px) { 
          grid-template-columns: 1fr 1fr 1fr;
        }
        justify-content: space-between;
        gap: 15px;

        .tag {
          align-items: center;
          justify-content: center;
          font-size: 13px;
          flex: 1;
          border-radius: 5px;
          padding: 10px;
          .tag-label {
            padding-top: 10px;
            text-align: center;
          }
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }

      & > button {
        margin-top: 30px;
        width: 150px;
        padding: 0 20px;
        background-color: transparent;
        border: 1px solid $orange;
        color: $orange;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          margin-left: 10px;
          & > path {
            stroke: $orange;
          }
        }
      }
    }

    .payment {
      padding: 32px;
      background-color: #f3ffff;
      // justify-content: flex-start !important;

      & > span:first-of-type {
        font-size: 25px;
        letter-spacing: -0.5px;
        line-height: 19px;
        margin-bottom: 8px;
        display: inline-block;
      }

      .payment-type {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        margin-top: 5px;
        font-size: 13px;
        line-height: 22px;
      }
    }

    .apply-button-container {
      display: flex;
    }

    .more-info-container {
      background-color: #f5f5f5;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .not-available {
        font-size: 20px;
      }

      & > .price-info {
        display: flex;
        flex-direction: column;
        padding-top: 2px;
      }

      & > .price-info > span:first-child {
        font-size: 20px;
        letter-spacing: -0.5px;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 8px;
      }

      & > .price-info > span:nth-child(2) {
        margin-top: 5px;
        font-size: 12px;
        line-height: 20px;
      }

      & > .price-info > span:nth-child(3) {
        font-size: 11px;
        line-height: 12px;
        color: #757d82;
      }

      & > .apply-button-container > button {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;

        & > svg {
          margin-left: 10px;
          transform: rotate(-90deg);
        }
      }
    }

    .price-container.payment {
      padding: 30px;
      background-color: #f3ffff;

      .payment-type {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }

    .up {
      transform: rotate(180deg);
    }
  }

  .details {
    max-height: 0;
    opacity: 0;
    position: relative;
    padding: 0;
    display: grid;
    margin-top: -5px;
    transition: all 300ms ease;
    box-shadow: 0 3px 10px #00000026;
    border-radius: 0 0 7.5px 7.5px;
    border-top: none;
    background-color: #FFFFFF;
    z-index: 1;
    grid-template-columns: 1fr;

    h5 {
      margin: 30px 10px 0px 10px;
      padding: 10px 20px;
      font-weight: bold;
      background-color: #71aba0;
      color: #FFFFFF;
      border-radius: 5px;
    }

    & p {
      margin: 0;
      font-size: 13px;
      letter-spacing: -0.32px;
      line-height: 23px;
      padding: 16px ;
    }


    &.show {
      opacity: 1;
      height: auto;
      max-height: 6000px;
    }
    .details-contents {
      padding: 10px 30px 10px 10px;
    }
  }
  @media all and (max-width: 1480px) {
    height: auto;
  }
  @media all and (max-width: 1280px) {
    .list-item-container {
      grid-template-columns: 1fr;
      grid-template-rows: 205px minmax(max-content, 205px) 205px;
      grid-template-areas:
        'img img'
        'main main'
        'price payType'
        'moreInfo moreInfo';

      .image-container {
        grid-area: img;
      }

      .main-content {
        grid-area: main;
      }

      .price-container {
        grid-area: price;

        & > .apply-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      .price-container.payment {
        grid-area: payType;
      }

      .more-info-btn-container {
        grid-area: moreInfo;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-orange {
          background-color: transparent;
          color: $orange;
          border: 1px solid $orange;
          font-size: 11px;
          line-height: 11px;
          cursor: pointer;
          height: 25px;
          border-radius: 25px;
          padding: 0 20px;
          width: 150px;
        }

        svg {
          margin-left: 10px;
        }

        path {
          stroke: $orange;
        }
      }
    }
    .details {
      grid-template-columns: 1fr;

      & > .text {
        grid-column-start: 1;
      }
    }
  }

  @media all and (max-width: 600px) {
    .list-item-container {
      grid-template-columns: 1fr;
      grid-template-areas:
        'img'
        'main'
        'price'
        'payType'
        'moreInfo';

      .price-container.payment {
        padding: 10px 20px 20px 20px;
      }
    }
  }
}
