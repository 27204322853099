@import './src/scss/colors.scss';

.login-page {
  padding-top: 130px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 114px);

  .content-container {
    max-width: 900px;
    margin: 0px auto;
  }

  .title {
    text-align: center;
    font-size: 45px;
    line-height: 55px;
    color: #262626;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .opportunities-container {
    max-width: 900px;
    margin: 0px auto;
    background-color: #FFFFFF;
    padding: 30px;
  }

}
