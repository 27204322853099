@import '../../../scss/colors.scss';

.clear-confirm-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  transform: translate(-50%, -50%);
  width: 418px;
  height: 185px;
  border-radius: 15px;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px;
    color: #262626;
    opacity: 1;
    padding: 30px 30px 45px 30px;
    margin: 0;
  }

  & > div {
    padding: 0 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    button {
      width: 100%;

      &:last-child {
        background-color: transparent;
        border: 1px solid $orange;
        color: $orange;
      }

      &:hover {
        background-color: rgba(252, 129, 36, 0.1);
        border: 1px solid #fc8124;
        color: #fc8124;
      }
    }
  }

  //media for screens less 925px

  @media (max-width: 925px) {
    width: 50%;
    height: fit-content;
    padding: 16px;
    border-radius: 7.5px;

    h3 {
      padding: 32px;
    }

    .buttonsContainer {
      padding: unset;
    }
  }

  @media (max-width: 575px) {
    width: 80%;

    button {
      height: 45px;
      border-radius: 7.5px;
    }
  }
}
