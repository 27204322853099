.stats-banner-section {
    $bottom-shift: 70px;
    $full-component-height: 450px;
    $stats-banner-bg: #549589;
    $stats-banner-cards-color: #6EBFB0;
    $icon-height: 70px;
    $stats-banner-text-color: #ffffff;

    position: relative;
    height: $full-component-height + $bottom-shift;
    color: $stats-banner-text-color;

    .background {
        background-color: $stats-banner-bg;
        height: $full-component-height;
        padding: 50px 100px 0 100px;
    }

    .background-container {
        height: 100%;
    }

    .shifted-container {
        height: 100%;
        width: 100%;
        transform: translateY($bottom-shift);
    }

    h2 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .top-layer {
        position: absolute;
        left: 0;
        top: $bottom-shift;
        transform: translateY(-$icon-height / 2);
        padding: 0 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: $stats-banner-text-color;

        div {
            width: 100%;
            height: 100%;
            justify-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

    
        img {
            background-color: $stats-banner-bg;
            height: $icon-height;
            width: $icon-height;
            padding: 0 10px;
            margin: auto;
        }

    }

    .arrow-box {
        display: flex;
        border: 1px solid #ffffff;
        border-radius: 70px;
        height: 100%;
    }

    .cards-box {
        position: absolute;
        height: 2 * $bottom-shift;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 2;

        .cards-flexbox {
            display: flex;
            flex-direction: row;
            gap: 1em;
            height: 100%;
            text-align: center;

            div {
                height: 100%;
                width: 100%;
                align-content: center;
                border-radius: 10px;
                background-color: $stats-banner-cards-color;
            }
        }
    }


    @media (max-width: 1100px) {
        height: auto;
        background-color: $stats-banner-bg;
        padding-bottom: 20px;

        .background {
            height: auto;
            padding: 50px 0 0 0;
        }

        .background-container {
            height: auto;
        }

        .shifted-container {
            display: none;
        }

        .arrow-icon {
            transform: rotate(90deg);
            transform-origin: center; 
        }

        .top-layer {
            position: relative;
            top: 0;
            height: auto;
            flex-direction: column;
            padding: 0;
            gap: 1em;

            .stat-item {
                border: 1px solid #FFFFFF;
                border-radius: 10px;
                padding: 10px;
            }
            img {
                padding: 0px;
            }
        }

        .cards-box {
            position: static;
            bottom: none;
            height: auto;

            .cards-flexbox {
                flex-direction: column;
                height: auto;
    
                div {
                    height: 1.5 * $bottom-shift;
                }
            }

        }

    }
}