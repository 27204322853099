// regular
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/helvetica-neue/helvetica_neue.woff') format('woff'),
    url('../assets/fonts/helvetica-neue/helvetica_neue.woff2') format('woff2'),
    url('../assets/fonts/helvetica-neue/helvetica_neue.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// bold
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/helvetica-neue/helvetica_neue_bold.woff')
      format('woff'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_bold.woff2')
      format('woff2'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

// thin
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/helvetica-neue/helvetica_neue_thin.woff')
      format('woff'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_thin.woff2')
      format('woff2'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

// light
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/helvetica-neue/helvetica_neue_light.woff')
      format('woff'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_light.woff2')
      format('woff2'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

// medium
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/helvetica-neue/helvetica_neue_medium.woff')
      format('woff'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_medium.woff2')
      format('woff2'),
    url('../assets/fonts/helvetica-neue/helvetica_neue_medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}
