@import './src/scss/colors.scss';

.about-us-page {
  padding-top: 130px;
  position: relative;
  top: 0;

  .title {
    font-size: 45px;
    line-height: 55px;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .notification-container {
    width: 100%;
    top: 80px;

    .notification-warning {
      background-color: $orange;
    }

    .notification-success {
      background-color: #00c343;
    }

    .notification-error {
      background-color: #de2525;
    }
  }

  .about-us-page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #599d90;
    padding-top: 75px;
    color: #FFFFFF;
    .content-container {
      max-width: 900px;
      margin: 0px auto;
    }
    p {
      font-size: 1.5em;
      line-height: 2em;
      font-weight: 100;
    }
    .masonry-container-layout {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      row-gap: 50px;
      column-gap: 40px;
      margin-bottom: 50px;
      .team-member {
        &:nth-child(1) {
          margin-top: 30px;
        }
        &:nth-child(3) {
          margin-top: 30px;
        }
        width: 300px;
        background-color: #FFFFFF;
        border-radius: 10px;
        color: #000000;
        padding: 15px;
        font-size: 1em;
        .team-member-profile-pic {
          width: 100%;
          border-radius: 10px;
          aspect-ratio: 1.0;
          background-color: #59749d;
          background-repeat: no-repeat;
          background-size: cover;
        }
        h3.name {
          font-size: 1.2em;
          margin: 10px 0px 0px 0px;
          padding: 0px;
        }
        h3.position {
          font-weight: 100;
          font-size: 1em;
          margin: 0px;
          padding: 0px;
          color: #599d90;
        }
        p.about {
          font-size: 1em;
          line-height: 1.25em;
        }
      }
    }
  }

  .our-story-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    padding-top: 75px;
    color: #262626;
    .title {
      font-size: 45px;
      line-height: 55px;
      color: #262626;
      margin-top: 0px;
      margin-bottom: 30px;
      font-weight: bold;
    }
    p {
      font-size: 1.5em;
      line-height: 2em;
      font-weight: 100;
    }
    .content-container {
      max-width: 900px;
      margin: 0px auto;
    }
  }


  //media for screens less 1120px

  @media (max-width: 1120px) {
    .about-us-page-header {
      padding: 75px 30px 30px 30px;
      .masonry-container-layout {
        grid-template-columns: 1fr 1fr;
        .team-member {
          width: 100%;
        }
      }
    }
    .our-story-section {
      padding: 75px 30px 30px 30px;
    }
  }

  @media (max-width: 600px) {
    .about-us-page-header {
      .masonry-container-layout {
        grid-template-columns: 1fr;
      }
    }
  }
}