@import './fonts.scss';
@import './utils.scss';
@import './colors.scss';

html {
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@media (min-width: $lg-breakpoint) {
  .MuiContainer-maxWidthLg {
    max-width: 1450px !important;
  }
}

.btn-orange {
  background-color: $orange;
  color: white;
  border: 0;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  height: 50px;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  &:disabled {
    cursor: not-allowed;
    background-color: rgba(252, 129, 36, 0.1);
    border: 1px solid rgba(252, 129, 36, 0.5);
    color: rgba(252, 129, 36, 0.5);
  }
}

.dashboard-top-block {
  height: 190px;
  background: transparent linear-gradient(270deg, $orange 0%, $orange 100%);
  display: flex;
  align-items: center;
  padding: 20px 60px;
  color: white;
  font-weight: bold;

  font-size: 45px;
  line-height: 50px;
}

.dashboard-breadcrumbs {
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 60px;
  box-shadow: 0 8px 16px #00000012;
  background-color: $main-gray;
  color: $main-black;
  position: relative;

  img {
    height: 7px;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    color: $main-black;
  }
}

.selected-sort-option {
  background-color: $orange;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

table.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #dee2e6;
}

table.table thead th {
  vertical-align: bottom;
  text-align: left;
}

table.table th {
  padding: .75rem;
  vertical-align: top;
}
table.table td {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: left;
}
