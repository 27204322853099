@import '../../../scss/fonts.scss';
@import '../../../scss/utils.scss';
@import '../../../scss/colors.scss';

.metric-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  transform: translate(-50%, -50%);
  min-width: 50vw;
  height: 65vh;
  border-radius: 15px;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 32px;

  .MuiAccordionSummary-content {
    justify-content: center;
  }

  .collapse-container {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: #262626;
    font-family: 'Helvetica Neue', serif;
    font-weight: 100;
    position: relative;
    overflow-y: auto;
    margin-top: 20px;
    width: 90%;

    .metric-modal-header {
      text-align: left;
    }

    &::-webkit-scrollbar {
      position: absolute;
      right: -8px;
      width: 10px;
    }

    .accordion-title {
      font-weight: bold;
    }
  }

  .close-icon {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid $orange;
    background-color: #ffffff;
    margin: 0;
    line-height: 0;
    color: $orange;
    transition: all 0.2s;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    cursor: pointer;

    &:hover {
      background-color: $orange;

      svg {
        color: white;
      }
    }
  }

  .MuiAccordionSummary-content {
    justify-content: flex-start;
    margin-left: unset;
  }

  .css-ew8zdy-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: unset;
  }

  .css-ounldo-MuiAccordionDetails-root {
    text-align: justify;
    padding: 16px 0 16px 8px;

    p {
      font-size: 15px !important;
    }
  }

  //media for screens less 795px

  @media (max-width: 795px) {
    min-width: 85vw;
    height: 70vh;

    .collapse-container {
      width: 95%;

      .metric-modal-header {
        width: 95%;
      }
    }
  }

  //media for screens less 495px

  @media (max-width: 495px) {
    height: 75vh;
    padding: 16px;

    .close-icon {
      top: 10px;
      right: 10px;
    }

    .collapse-container {
      width: 86%;

      .metric-modal-header {
        width: 90%;
        font-size: 16px;
        line-height: 23px;
        margin: 0 0 16px 0;
      }
    }
  }
}
