@import './src/scss/colors.scss';

.map-draw-page {
  margin-top: 60px;
  display: flex;
  max-height: calc(100vh - 60px);
  overflow: hidden;

  .notification-container {
    width: 100%;
    position: absolute;
    top: 80px;
    .notification-warning {
      background-color: $orange;
    }
    .notification-success {
      background-color: #00c343;
    }
    .notification-error {
      background-color: #de2525;
    }
  }

  .left-sidebar {
    min-width: 440px;
    max-width: 440px;
    background-color: white;
    overflow-y: auto;

    .blue-line {
      height: 2px;
      background-color: #e8fafa;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .search-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110px;
      background-color: #ebebeb;
      padding: 0 60px;
      position: relative;
      .search-input {
        width: 100%;
        .input-inner {
          display: flex;
          align-items: center;
          background-color: white;
          height: 100%;
          border-radius: 50px;
          overflow: hidden;
          padding: 5px 15px;
          box-shadow: inset 1.5px 1.5px 3px #00000029;

          input {
            border: 0;
            width: 100%;
            outline: none;
            font-size: 16px;
            color: $main-black;
            height: 30px;

            &::placeholder {
              color: #8c8c8c;
              text-align: center;
            }
          }

          img {
            height: 20px;
            width: 20px;
          }
        }

        .search-autocomplete-list {
          box-shadow: 0 0 20px #00000029;
          z-index: 1000;
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          margin-top: 10px;

          .item {
            padding: 8px 10px;
            background-color: white;
            width: 865px;
            text-align: left;
            cursor: pointer;

            &:not(.loading):hover {
              background-color: $orange;
              color: white;
            }

            &:not(:last-child) {
              border-bottom: 1px solid silver;
            }
          }
        }
      }
    }

    .map-info-block {
      padding: 30px 60px;
      width: 100%;

      .text {
        margin-top: 20px;
        color: #262626;
      }

      .title + div {
        border-radius: 7.5px;
        overflow: hidden;

        > iframe {
          width: 100% !important;
          height: 100% !important;
        }
      }
      > .title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 30px;

        img {
          height: 40px;
          width: 40px;
          margin-right: 15px;
        }
      }

      .specs {
        .spec {
          margin-bottom: 30px;
          display: flex;

          .name {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            min-width: 80px;
            margin-right: 30px;
          }

          .value {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      button {
        width: 100%;
        border-radius: 20px;
        height: 40px;
        font-weight: 500;

        &:hover {
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid $orange;
          color: $orange;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid rgba(252, 129, 36, 0.5);
          color: rgba(252, 129, 36, 0.5);
        }
      }
    }
  }

  .map-block {
    width: 100%;
    position: relative;

    .map-actions {
      display: flex;
      position: absolute;
      z-index: 200;
      top: 30px;
      width: 100%;
      justify-content: center;
      pointer-events: none;

      .map-actions-wrapper {
        display: flex;

        > button {
          height: 70px;
          width: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          margin-right: 5px;
          font-size: 14px;
          line-height: 28px;
          padding: 15px 8px 8px;
          border-radius: 12px;
          backdrop-filter: blur(15px);
          background-color: rgba(38, 38, 38, 0.65);

          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
          white-space: nowrap;
          border: 0;
          pointer-events: visible;

          &:hover,
          &.active {
            background-color: $orange;
          }

          img {
            height: 24px;
            width: auto;
            margin-bottom: auto;
          }
        }
      }

      .submitButtonWrapper {
        display: none;
      }
    }

    .extra-actions,
    .drawnOutlineWrapper,
    .searchWrapper,
    .videoWrapper {
      display: none;
    }

    .map-wrapper {
      height: 100%;
      background-color: lightgray;
      width: 100%;
    }
  }

  .mapboxgl-canvas {
    width: 100%;
    height: 100%;
  }

  //media for screens less 925px

  @media (max-width: 1025px) {
    margin-top: unset;
    max-height: 100%;

    .left-sidebar {
      display: none;
    }

    .map-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .map-actions {
        flex-direction: column;
        align-items: center;
        width: 100%;
        top: unset;
        bottom: 2.5%;

        .map-actions-wrapper {
          button {
            width: 65px;
            height: 65px;
            margin-right: 8px;
            background-color: rgba(38, 38, 38, 1);
          }

          button:first-child {
            margin: 0 8px;
          }

          .clearButton {
            display: none;
          }
        }

        .submitButtonWrapper {
          display: flex;
          justify-content: center;
          width: 35%;
          margin-top: 10px;

          button {
            width: 100%;
            height: 45px;
            pointer-events: visible;

            &.disabled {
              cursor: not-allowed;
              background-color: rgba(250, 250, 250, 0.9);
              border: 1px solid rgba(38.4, 38.4, 38.4, 0.3);
              color: rgba(38.4, 38.4, 38.4, 0.2);
            }
          }
        }
      }

      .extra-actions {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        width: 100%;
        z-index: 200;
        top: 72px;

        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 33.33%;
          height: 45px;
          padding: 5px;
          background-color: #fc8124;
          backdrop-filter: blur(15px);
          border: 1px solid #ffffff;
          outline: none;
          color: #ffffff;
          font-size: 14px;
          transition: background-color 0.3s ease-in-out;
          white-space: nowrap;
          pointer-events: visible;
          cursor: pointer;
        }

        .isActiveSearch,
        .isActiveHelp,
        .isActiveClear {
          background-color: #262626;
        }

        svg {
          margin-right: 4px;
        }
      }

      .drawnOutlineWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        background-color: rgba(250, 250, 250, 0.1);
        backdrop-filter: blur(15px);
        padding: 10px 5px 10px 10px;
        font-size: 14px;
        color: #ffffff;
        width: 100%;
        z-index: 200;
        top: 117px;
        transition: top ease-in-out 0.5s;

        .specs {
          align-self: flex-start;
          width: 100%;
          margin-top: 16px;
          padding-right: 5px;

          & > div:not(:last-child) {
            margin-bottom: 16px;
          }

          .spec {
            display: flex;
            align-items: center;

            .name {
              width: 20%;
              margin-right: 20px;
            }

            .value {
              display: flex;
              justify-content: flex-end;
              text-align: right;
              width: 80%;
            }
          }
        }

        .expandWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-weight: 600;
          cursor: pointer !important;

          .up {
            transform: rotate(180deg);
          }
        }
      }

      .drawnOutlineWrapper.hidden {
        top: -117px;
      }

      .drawnOutlineWrapper.blured {
        backdrop-filter: unset;
        background-color: transparent;
        filter: blur(3px);
        pointer-events: none;
      }

      .searchWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        background-color: rgba(250, 250, 250, 0.1);
        backdrop-filter: blur(5px);
        padding: 10px 10px 0 10px;
        font-size: 14px;
        color: #ffffff;
        width: 100%;
        z-index: 200;
        top: 117px;
        right: 100%;
        transition: right ease-in-out 0.5s;

        .search-input {
          width: 100%;

          .input-inner {
            display: flex;
            align-items: center;
            background-color: white;
            height: 100%;
            width: 100%;
            border-radius: 12px;
            overflow: hidden;
            padding: 5px 15px;
            box-shadow: inset 1.5px 1.5px 3px #00000029;

            input {
              border: 0;
              width: 100%;
              outline: none;
              font-size: 15px;
              color: $main-black;
              height: 30px;

              &::placeholder {
                color: #8c8c8c;
                text-align: center;
              }
            }

            img {
              height: 15px;
              width: 15px;
              margin-left: 10px;
              cursor: pointer;
            }
          }

          .search-autocomplete-list {
            box-shadow: 0 0 20px #00000029;
            z-index: 1000;
            width: 100%;
            max-height: 235px;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            overflow: auto;
            overflow-x: hidden;
            margin-top: 10px;

            .item {
              padding: 8px 10px;
              background-color: white;
              color: #262626;
              font-size: 15px;
              text-align: left;
              cursor: pointer;

              &:not(.loading):hover {
                background-color: $orange;
                color: white;
              }

              &:not(:last-child) {
                border-bottom: 1px solid silver;
              }
            }
          }
        }
      }

      .searchWrapper.visible {
        right: 0%;
      }

      .searchWrapper.opened {
        padding-bottom: 10px;
      }
    }

    .map-wrapper.blured {
      filter: blur(3px);
      pointer-events: none;

      .mapboxgl-ctrl-logo,
      .mapboxgl-ctrl-bottom-right {
        display: none;
      }
    }

    .map-actions.blured {
      filter: blur(3px);
      pointer-events: none;

      .map-actions-wrapper,
      .submitButtonWrapper {
        button {
          pointer-events: none;
        }
      }
    }

    .videoWrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #ffffff;
      padding: 10px;
      font-size: 14px;
      color: #262626;
      width: 80%;
      z-index: 200;
      border-radius: 7.5px;

      & > div {
        width: 100%;
      }

      & > div:not(:last-child) {
        margin-bottom: 20px;
      }

      .text {
        max-height: 135px;
        padding: 0 10px 10px 0;
        font-weight: 500;
        text-align: justify;
        overflow-y: auto;
      }
    }

    .videoWrapper.visible {
      display: flex;
    }
  }

  //media for screens less 525px

  @media (max-width: 575px) {
    .submitButtonWrapper {
      width: 55% !important;
    }

    iframe {
      height: 100% !important;
    }
  }
}
