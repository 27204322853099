.timeline-entry {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    display: flex;
    width: 90%;
    &.flip {
      flex-direction: row-reverse;
      .details {
        .details-box {
          margin-left: 0px;
          margin-right: -100px;
        }
      }
    }
    .pic {
      max-width: 500px;
      z-index: 2;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #59749d;
      filter: drop-shadow(3px 3px 10px #999999);
      transform: rotate(2deg);
      border: 20px solid #FFFFFF;
      width: 600px;
      @media (max-width: 600px) {
        width: unset;
        max-width: 600px;
      }
      aspect-ratio: 1.33;
    }
    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      .details-box {
        background-color: #FFFFFF;
        border-radius: 10px;
        margin-left: -100px;
        padding: 20px;
        z-index: 3;
      }
      h4 {
        margin: 0px;
        padding: 0px;
        font-size: 1.8em;
      }
      p.date {
        color: #599d90;
        font-size: 1em;
        font-weight: 300;
      }
      p.info {
        font-size: 1em;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 600px) {
  .timeline-entry {
    height: unset;
    margin-top: 75px;
    .inner {
      flex-direction: column;
      .details {
        .details-box {
          margin-top: -50px;
          margin-left: auto;
          margin-right: auto;
          width: 90%;
        }
      }
      &.flip {
        flex-direction: column;
        .details {
          .details-box {
            margin-top: -50px;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
          }
        } 
      }
    }
  }
}