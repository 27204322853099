.main-grid-component {
  display: flex;
  flex-direction: column;
  height: 100vh;

  footer {
    margin-top: auto;
  }

  .main-grid-content.application {
    background-color: #e8fafa;
  }
}

@supports (-webkit-touch-callout: none) {
  .on-footer {
    position: absolute;
    bottom: 0;
    transition: padding 226ms ease-out;
    padding-bottom: calc(100vh - 80px + 2%);
  }

  .webkit-gap {
    position: fixed;
    top: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
