@import './src/scss/colors.scss';

.search-banner-cta {
  background-color: #549589;
  padding: 75px 30px;
  .content-container {
    max-width: 900px;
    margin: 0px auto;
    text-align: center;
    h3 {
      font-size: 45px;
      line-height: 55px;
      color: #FFFFFF;
      margin-top: 0px;
      margin-bottom: 30px;
      font-weight: bold;
    }

    .search-input {
      width: 100%;
      height: 80px;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 15px;
      backdrop-filter: blur(20px);
      border-radius: 50px;
      position: relative;

      .input-inner {
        display: flex;
        align-items: center;
        background-color: white;
        height: 100%;
        border-radius: 50px;
        overflow: hidden;
        padding: 5px 25px;

        input {
          border: 0;
          width: 100%;
          outline: none;
          font-size: 20px;
          color: $main-black;
          height: 30px;
          text-align: center;

          &::placeholder {
            color: #8c8c8c;
            text-align: center;
          }
        }

        img {
          height: 20px;
          width: 20px;
        }
      }

      .search-autocomplete-list {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        overflow: hidden;
        margin-top: 10px;

        .item {
          padding: 8px 10px;
          background-color: white;
          text-align: left;
          cursor: pointer;

          &:not(.loading):hover {
            background-color: $orange;
            color: white;
          }

          &:not(:last-child) {
            border-bottom: 1px solid silver;
          }
        }
      }
    }

    .btn-orange {
      margin-top: 30px;
      width: 150px;
      height: 50px;
      border-radius: 25px;
      font-size: 20px;
    }
  }
}
