@import '../../../scss/colors.scss';
@import '~react-calendar/dist/Calendar.css';
@import '~react-phone-number-input/style.css';

.application-container {
  width: 100%;
  max-width: 1145px;
  text-align: center;
  min-height: calc(100vh - 234px);
  margin: 130px auto;

  .notification-container {
    width: 100%;
    top: 80px;
    .notification-warning {
      background-color: $orange;
    }
    .notification-success {
      background-color: #00c343;
    }
    .notification-error {
      background-color: #de2525;
    }
  }

  .form {
    padding: 60px;
    width: 100%;
    border-radius: 7.5px;
    background-color: white;
    min-height: 400px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    label {
      span {
        margin-left: 5px;
        color: #de2525;
      }
    }

    .questions-form-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 30px;
      padding-bottom: 30px;
    }

    .phone-input-select {
      position: relative;
      .PhoneInputCountry {
        height: 100%;
        position: absolute;
        left: 10px;
      }

      & > input {
        padding-left: 64px !important;
      }
    }
    .submit-container {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      small {
        margin-top: 10px;
        color: #757d82;
      }

      button {
        margin-top: 25px;
        width: 80%;
        transition: 0.1s;

        &:hover {
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid $orange;
          color: $orange;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid rgba(252, 129, 36, 0.5);
          color: rgba(252, 129, 36, 0.5);
        }
      }
    }

    .contact-info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 60px 60px 0;
      gap: 30px;
      grid-template-rows: 65px 65px;

      label {
        color: #4d4d4d;
      }

      .input-mobile input,
      .input-date .input-wrapper {
        margin-top: 5px;
      }

      .input-mobile:has(.has-error) {
        & input {
          border: 1px solid red;
        }
        & span {
          color: red;
        }
      }

      .input-mobile,
      .input-date,
      .input-time-slot {
        text-align: start;
      }
      .input-time-slot {
        z-index: 11;

        .calendar-container {
          position: absolute;
        }
      }
      .input-date {
        .input-wrapper {
          position: relative;
          color: white;

          input::-webkit-inner-spin-button,
          input::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }

          .icon-container {
            display: inline-flex;
            position: absolute;
            right: 0;
            background-color: $orange;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 0 7.5px 7.5px 0;
            svg {
              font-size: 30px;
            }
          }
        }
      }
      .input-mobile,
      .input-date {
        input::-webkit-date-and-time-value {
          text-align: left;
          padding-left: 8px;
        }

        #date {
          pointer-events: none;
          min-width: calc(100% - 32px);
        }

        input {
          font-size: 16px;
          color: #757d82;
          padding-left: 16px;
          width: 100%;
          height: 40px;
          background: #ffffff 0 0 no-repeat padding-box;
          border: 1px solid #d6d8e1;
          border-radius: 5px;
          opacity: 1;

          &:focus {
            outline: none;
          }
        }
      }
    }
    .checkboxes {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  //media for screens less 795px

  @media (max-width: 795px) {
    width: unset;
    max-width: unset;
    min-height: unset;
    margin: 120px 0 60px 0;

    .contact-info {
      height: max-content;
      padding: unset !important;
      grid-template-columns: 1fr !important;
    }
  }

  //media for screens less 600px

  @media (max-width: 600px) {
    margin: 120px 0 1px 0;

    h1,
    p {
      text-align: center;
      padding: 0 16px;
    }

    h1 {
      font-size: 26px;
      margin: unset;
    }

    p {
      font-size: 18px;
      margin: 16px 0 60px 0;
    }

    .form {
      margin-top: unset;
      padding: 32px;
      border-radius: unset;
      gap: 32px;

      .questions-form-container {
        grid-template-columns: 1fr;
        padding-bottom: unset;

        .answers {
          flex-direction: column;
          align-items: flex-start;

          .answer {
            label {
              text-align: justify;
            }
          }
        }
      }

      .contact-info {
        height: max-content;
      }
    }

    .submit-container {
      width: unset !important;
      margin-top: 16px;

      .checkboxes {
        .checkbox-container {
          justify-content: flex-start;

          .checkbox {
            width: 15px;
            height: 15px;
          }

          span {
            width: 90%;
            text-align: justify;
            font-size: 14px;
          }
        }
      }

      .btn-orange {
        width: 60% !important;
        height: 45px !important;
        margin-top: 32px !important;
      }

      small {
        width: 100%;
        font-size: 13px;
      }
    }
  }
}
