@import '../../../scss/fonts.scss';
@import '../../../scss/colors.scss';

.calendar-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  transform: translate(-50%, -50%);
  width: 440px;
  height: 580px;
  border-radius: 15px;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .react-calendar {
    width: 100%;
    height: 100%;
    border: none;

    &__navigation {
      height: 90px;
      & > * {
        font-size: 25px;
        line-height: 60px;
      }
    }

    &__viewContainer {
      height: 240px;
      padding: 20px;
      .react-calendar__tile--now {
        background-color: rgba(252, 129, 36, 0.2);

        &:hover {
          background-color: rgba(252, 129, 36, 0.4);
        }
      }
      .react-calendar__month-view {
        &__days__day {
          border-radius: 50%;
          padding: 19px;
        }
        &__days__day.react-calendar__tile--active {
          background-color: $orange;
        }
        &__days button,
        &__weekdays__weekday {
          abbr {
            text-decoration: none;
            font-size: 16px;
            letter-spacing: -0.32px;
            line-height: 19px;
            font-family: 'Helvetica Neue', serif;
          }
        }
      }
    }
  }

  .submit-container {
    width: 100%;
    margin: 0;
    padding: 0 30px;

    & > div {
      //border-top: 1px solid rgba(252, 129, 36, 0.4);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      gap: 15px;
      button {
        padding: 0 20px;
        border: 1px solid transparent;
        transition: 0.1s;

        &:last-child {
          background-color: transparent;
          border: 1px solid #fc8124;
          color: #fc8124;
        }

        &:hover {
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid #fc8124;
          color: #fc8124;
        }
      }
    }
  }

  //media for screens less 795px

  @media (max-width: 795px) {
    width: 360px;
    height: 480px;
    justify-content: center;

    .react-calendar__navigation {
      height: unset;
      margin-bottom: unset;

      &__arrow,
      &__label {
        font-size: 20px;
      }
    }

    .react-calendar {
      height: unset;
    }

    .react-calendar__viewContainer {
      height: unset;
      padding: 16px;
    }

    .react-calendar__month-view__days {
      margin-top: 16px;

      button {
        padding: 12px !important;
      }
    }

    .submit-container {
      & > div {
        border: none;
        padding: unset;
      }

      .btn-orange {
        height: 45px;
        border-radius: 7.5px;
        padding: 0 40px;
      }
    }
  }

  //media for screens less 425px

  @media (max-width: 425px) {
    width: 80%;
    height: 400px;

    .react-calendar__navigation {
      &__arrow,
      &__label {
        font-size: 16px;
        line-height: 23px;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      padding: unset;
    }

    .react-calendar__month-view__days {
      button {
        padding: 8px !important;
      }
    }

    .submit-container {
      .btn-orange {
        padding: 0 35px;
      }
    }
  }

  //media for screens less 350px

  @media (max-width: 350px) {
    .submit-container {
      .btn-orange {
        padding: 0 20px;
      }
    }
  }
}
