@import '../../../scss/colors.scss';
.checkbox-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .link {
    color: $orange;
    text-decoration: none;
  }
  .checkbox {
    width: 17px;
    height: 17px;
    position: relative;
    color: $orange;
    margin-right: 10px;
    border: 1.5px solid #757d82;
    border-radius: 50%;
    line-height: 0;

    svg {
      position: absolute;
      top: -3px;
      right: -4px;
      path {
        stroke-width: 4px;
        stroke: $orange;
      }
    }
  }
}
