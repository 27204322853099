@import './src/scss/colors.scss';

.login-page {
  padding-top: 130px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 114px);

  .content-container {
    max-width: 900px;
    margin: 0px auto;
  }

  .title {
    text-align: center;
    font-size: 45px;
    line-height: 55px;
    color: #262626;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .login-container {
    max-width: 600px;
    margin: 0px auto;
    background-color: #FFFFFF;
    padding: 60px;

    label {
      display: block;
      margin-bottom: 10px;
    }

    .input-field-row {
      margin-bottom: 20px;
    }

    input[type=text], input[type=password] {
      font-size: 16px;
      color: #757d82;
      padding-left: 16px;
      width: 100%;
      height: 40px;
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #d6d8e1;
      border-radius: 5px;
      opacity: 1;
    }
    button.btn-orange {
      width: 100%;
    }
  }

}
