@import '../../../scss/fonts.scss';
@import '../../../scss/utils.scss';
@import '../../../scss/colors.scss';

a.back-link {
  color: $orange;
  font-size: 0.9em;
}

.plot-pip {
  border: 1px solid #757d82;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
  border-radius: 5px;
  div {
    display: flex;
    align-self: flex-start;
    padding: 5px 10px;
    font-weight: bold;
  }
  div:nth-child(1) {
    background-color: #757d82;
    color: #FFFFFF;
    
  }
  div:nth-child(2) {
    color: #757d82;
    font-weight: bold;
  }
}

.header {
  height: 375px;
  width: 100%;
  background-image: linear-gradient(
      rgba(38, 38, 38, 0.45),
      rgba(38, 38, 38, 0.45)
    ),
    url('../../../assets/imgs/opportunity background.svg');
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  & > h1 {
    margin: 0;
    font-size: 68px;
    letter-spacing: -1.38px;
    line-height: 85px;
  }
  & > h4 {
    margin-top: 10px;
    margin-bottom: 60px;
    font-size: 25px;
    letter-spacing: 0;
    line-height: 30px;
  }
}

.opportunities-outer-container {
  background-color: #f2f2f2;
}

.main-content {
  max-width: 1200px;
  padding: 45px 60px;
  margin: 0px auto;
  grid-template-columns: 235px 1fr 1fr;

  h1:not(:first-child) {
    margin-top: 100px;
  }

  .opportunities-top-bar {
    display: grid;
    grid-template-columns: 170px 1fr;
  }

  .opportunties-heading-bar {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .apply-to-all-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .back-link-container, .plot-pip-container {
    display: flex;
    align-items: center;
  }

  & > .left {
    grid-column: 1 / 2;
    height: 100%;

    .back-btn-container {
      display: flex;
      justify-content: flex-start;
      button {
        padding: 0 20px;
      }
      .left-arrow {
        transform: rotate(90deg);
        margin-right: 10px;

        path {
          stroke: $orange;
        }
      }
    }

    .btn-orange {
      background-color: transparent;
      color: $orange;
      border: 1px solid $orange;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
      height: 50px;
      border-radius: 25px;
    }

    .sort {
      margin-top: 45px;

      .sort-select-container {
        margin-top: 20px;
        .sort-select-input {
          width: 100%;
          height: 40px;
          border: 1px solid #d6d8e1;
          border-radius: 7.5px;
          color: #808080;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;
            padding: 0 15px;
          }

          .sort-select-icon {
            width: 40px;
            height: 40px;
            background-color: $orange;
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0 7.5px 7.5px 0;
            margin-right: -1px;

            svg {
              transform: rotate(0);
              transition: 0.1s;
            }
          }
        }

        .sort-select-input.opened {
          border-radius: 7.5px 7.5px 0 0;

          .sort-select-icon {
            border-radius: 0 7.5px 0 0;
            svg {
              transform: rotate(180deg);
              transition: 0.1s;
            }
          }
        }

        .sort-select-options {
          width: 100%;
          border-radius: 0 0 7.5px 7.5px;
          border: 1px solid #d6d8e1;
          border-top: none;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          background-color: #fff;
          transition: opacity 0.2s;

          &.visible {
            visibility: visible;
            opacity: 1;
          }

          & > .options-item {
            width: 100%;
            height: 40px;
            background-color: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 16px;
          }

          & > .options-item.selected {
            background-color: $orange;
            color: white;
          }

          & > .options-item:not(:first-child),
          & > .options-item:last-child {
            border-top: 1px solid #d6d8e1;
          }
        }
      }

      span {
        font-size: 25px;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }
  & > .right {
    grid-column: 2/3;
    position: relative;
  }

  .list-item-container {
    grid-template-rows: auto;

    .more-info-container {
      align-items: center;
    }

    .apply-button-container {
      justify-content: center;
    }
  }
}

@media all and (max-width: 1280px) {
  .main-content {
    grid-template-columns: 235px 1fr;

    & > .right .details {
      padding: 25px;
    }
  }
}

@media all and (max-width: 1025px) {
  .main-content {
    grid-template-columns: 1fr;

    .opportunities-top-bar {
      grid-template-columns: 1fr;
    }
    .opportunties-heading-bar {
      grid-template-columns: 1fr;
      h1 {
        text-align: center;
      }
    }

    .back-link-container, .plot-pip-container, .apply-to-all-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
    }

    & > .left {
      grid-column: 1;
      z-index: 111;

      .sort {
        height: 120px;
      }
    }

    & > .right {
      grid-column: 1;

      .details {
        padding: 10px;
      }
    }
  }
}

//media for screens less 1280

@media (max-width: 1280px) {
  .price-container {
    align-items: center;
    .btn-orange {
      width: 40%;
    }
  }
}

//media for screens less 825px

@media (max-width: 825px) {
  .price-container {
    .btn-orange {
      width: 50%;
    }
  }
}

//media for screens less 575px

@media (max-width: 575px) {
  .header {
    height: 275px;

    h1 {
      font-size: 50px;
      line-height: unset;
      margin: 0;
    }
  }
}

//media for screens less 495px

@media (max-width: 495px) {
  .header {
    padding: 120px 0 60px 0;
    height: unset;

    h1 {
      font-size: 36px;
      line-height: unset;
      text-align: center;
      margin: unset;
    }

    h4 {
      font-size: 18px;
      line-height: unset;
      text-align: center;
      margin: 8px 0 0 0;
      padding: 0 10px;
    }
  }

  .main-content {
    padding: 32px 16px 16px 16px;

    & > .left {
      .back-btn-container {
        justify-content: center;

        button {
          height: unset;
          padding: 10px 15px;

          svg {
            margin-right: 5px !important;
          }
        }
      }

      .sort {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 90px;
        margin: unset;

        span {
          font-size: 18px;
          margin: 8px 0;
        }

        .sort-select-container {
          width: 100%;
          margin: unset;
        }
      }
    }

    & > .right {
      .main-content {
        padding: 16px;

        h3 {
          font-size: 20px;
        }

        .tags {
          & > .tag {
            width: 100%;
          }
        }
      }

      .price-container {
        justify-content: center;
        align-items: center;
        padding: 16px;

        .btn-orange {
          width: 60%;
          height: 45px;
        }
      }

      .details {
        padding: 16px;

        .btn-orange {
          width: 60%;
          height: 45px;
          margin-top: 16px;
        }
      }
    }
  }
}
