.loading-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  transform: translate(-50%, -50%);
  width: 575px;
  height: 450px;
  border-radius: 15px;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    text-align: center;
    font-size: 36px;
    letter-spacing: -0.72px;
    line-height: 44px;
    color: #000;
    opacity: 1;
    padding: 60px 60px 45px 60px;
    margin: 0;
  }

  & > h4 {
    margin: 30px 0px 0px 0px;
  }

  & > div {
    width: 90px;
    height: 90px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  & > p {
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 23px;
    color: #000;
    opacity: 1;
    padding: 30px 60px 60px 60px;
    margin: 0;
  }

  #ewzcB3SVatU4 {
    animation: ewzcB3SVatU4_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU4_c_o {
    0% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU5 {
    animation: ewzcB3SVatU5_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU5_c_o {
    0% {
      opacity: 0;
    }
    8.333333% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU6 {
    animation: ewzcB3SVatU6_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU6_c_o {
    0% {
      opacity: 0;
    }
    16.666667% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU7 {
    animation: ewzcB3SVatU7_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU7_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    33.333333% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU8 {
    animation: ewzcB3SVatU8_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU8_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    77.777778% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU9 {
    animation: ewzcB3SVatU9_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU9_c_o {
    0% {
      opacity: 0;
    }
    72.222222% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU10 {
    animation: ewzcB3SVatU10_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU10_c_o {
    0% {
      opacity: 0;
    }
    69.444444% {
      opacity: 0;
    }
    72.222222% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU11 {
    animation: ewzcB3SVatU11_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU11_c_o {
    0% {
      opacity: 0;
    }
    47.222222% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU12 {
    animation: ewzcB3SVatU12_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU12_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    52.777778% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU13 {
    animation: ewzcB3SVatU13_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU13_c_o {
    0% {
      opacity: 0;
    }
    52.777778% {
      opacity: 0;
    }
    55.555556% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU14 {
    animation: ewzcB3SVatU14_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU14_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    36.111111% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU15 {
    animation: ewzcB3SVatU15_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU15_c_o {
    0% {
      opacity: 0;
    }
    36.111111% {
      opacity: 0;
    }
    38.888889% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU16 {
    animation: ewzcB3SVatU16_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU16_c_o {
    0% {
      opacity: 0;
    }
    38.888889% {
      opacity: 0;
    }
    41.666667% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU17 {
    animation: ewzcB3SVatU17_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU17_c_o {
    0% {
      opacity: 0;
    }
    41.666667% {
      opacity: 0;
    }
    44.444444% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU18 {
    animation: ewzcB3SVatU18_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU18_c_o {
    0% {
      opacity: 0;
    }
    44.444444% {
      opacity: 0;
    }
    47.222222% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU19 {
    animation: ewzcB3SVatU19_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU19_c_o {
    0% {
      opacity: 0;
    }
    55.555556% {
      opacity: 0;
    }
    58.333333% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU20 {
    animation: ewzcB3SVatU20_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU20_c_o {
    0% {
      opacity: 0;
    }
    58.333333% {
      opacity: 0;
    }
    61.111111% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU21 {
    animation: ewzcB3SVatU21_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU21_c_o {
    0% {
      opacity: 0;
    }
    61.111111% {
      opacity: 0;
    }
    63.888889% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU22 {
    animation: ewzcB3SVatU22_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU22_c_o {
    0% {
      opacity: 0;
    }
    63.888889% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #ewzcB3SVatU23 {
    animation: ewzcB3SVatU23_c_o 3600ms linear infinite normal forwards;
  }
  @keyframes ewzcB3SVatU23_c_o {
    0% {
      opacity: 0;
    }
    66.666667% {
      opacity: 0;
    }
    69.444444% {
      opacity: 1;
    }
    94.444444% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  //for the screens less 600px

  @media (max-width: 600px) {
    width: 80%;
    height: unset;
    padding: 16px;

    h3 {
      padding: unset;
      font-size: 30px;
    }

    .loading {
      margin: 20px 0;
    }

    p {
      width: 80%;
      padding: unset;
    }
  }

  //for the screens less 400px

  @media (max-width: 400px) {
    width: 90%;

    h3 {
      font-size: 26px;
      line-height: 36px;
    }

    p {
      width: 95%;
      font-size: 14px;
    }
  }
}
