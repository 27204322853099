@import '../../../scss/colors.scss';
.sort-select-container {
  margin-top: 5px;
  .sort-select-input {
    width: 100%;
    height: 40px;
    border: 1px solid #d6d8e1;
    border-radius: 7.5px;
    color: #808080;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      padding: 0 15px;
    }

    .sort-select-icon {
      width: 40px;
      height: 40px;
      background-color: $orange;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 0 7.5px 7.5px 0;
      margin-right: -1px;
      color: white;

      svg {
        transform: rotate(0);
        transition: 0.1s;
      }
    }
  }

  .sort-select-input.opened {
    border-radius: 7.5px 7.5px 0 0;
    border-bottom: none;

    .sort-select-icon {
      background-color: #262626;
      border-radius: 0 7.5px 0 0;
      svg {
        transform: rotate(180deg);
        transition: 0.1s;
        //background-color: #000;
      }
    }
  }

  .sort-select-options {
    width: 100%;
    border-radius: 0 0 7.5px 7.5px;
    border: 1px solid #d6d8e1;
    border-top: none;
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    background-color: #fff;
    transition: opacity 0.2s;

    &.visible {
      visibility: visible;
      opacity: 1;
      height: auto;
    }

    & > .options-item {
      &:hover {
        background-color: rgba(252, 129, 36, 0.1);
      }
      cursor: pointer;
      width: 100%;
      height: 40px;
      background-color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 16px;
    }

    & > .options-item.selected {
      background-color: $orange;
      color: white;
    }

    & > .options-item:not(:first-child),
    & > .options-item:last-child {
      //border-top: 1px solid #D6D8E1 ;
    }
  }
}
