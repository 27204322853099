.error-page-wrapper {
  width: 100%;
  height: calc(100vh - 60px - 213px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;
  .error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2,
    button {
      font-weight: 100;
      font-size: 40px;
    }

    button {
      font-size: 20px;
      width: 100%;
    }
  }
}
