@import './src/scss/colors.scss';

.empty-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  box-shadow: 0 3px 10px #00000026;
  background-color: #FFFFFF;
  padding: 30px 60px;
  border-radius: 15px;

  h2 {
    font-size: 2em;
    text-align: center;
  }

  p {
    font-size: 16px;
    text-align: center;
  }

  form.no-opportunities-sign-up {
    display: flex;
    flex-direction: column;

    input[type=email] {
      font-size: 16px;
      color: #757d82;
      padding-left: 16px;
      width: 100%;
      height: 40px;
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #d6d8e1;
      box-shadow: 0 3px 10px #00000026;
      text-align: center;
      border-radius: 5px;
      opacity: 1;

      &:focus {
        outline: none;
      }
    }

    button.btn-orange {
      width: 200px;
      transition: 0.1s;

      &:hover {
        background-color: rgba(252, 129, 36, 0.1);
        border: 1px solid $orange;
        color: $orange;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: rgba(252, 129, 36, 0.1);
        border: 1px solid rgba(252, 129, 36, 0.5);
        color: rgba(252, 129, 36, 0.5);
      }
    }
  }
  
  .form-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  }


  & > {
    svg {
      width: 100px;
      height: 100px;
    }
  }
}
