@import './src/scss/colors.scss';

.article-page {
  .content-wrapper {
    padding: 60px 32px 0 32px;

    & > div {
      width: 100%;
      max-width: 100% !important;
    }

    .content {
      padding: 60px 32px;
      background-color: white;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-img-block {
        width: 100%;
        height: 420px;

        img {
          border-radius: 7.5px;
          background-size: 100% auto;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .article-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
      }
    }
  }

  //media for screens less 975px

  @media (max-width: 975px) {
    .dashboard-breadcrumbs {
      padding: 32px 16px;
      font-size: 16px;
    }

    .content-wrapper {
      padding: 32px 32px 0 32px;

      &-container {
        margin: unset;
        padding: unset;

        .content {
          padding: 32px;

          .top-img-block {
            width: 100%;
            height: unset;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .dashboard-top-block {
      justify-content: center;
      text-align: center;
      font-size: 36px;
      padding: 120px 16px 60px 16px;
    }

    .content-wrapper {
      padding: 32px 16px;

      .content {
        padding: 16px;

        .top-img-block {
          width: 100%;
          height: unset;
        }
      }
    }
  }
}
