@import './src/scss/colors.scss';

.partners-page {
  padding-top: 130px;
  background-color: $main-gray;
  min-height: calc(100vh - 114px);

  .title {
    text-align: center;
    font-size: 45px;
    line-height: 55px;
    color: $main-black;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .description {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 100;
  }

  .partners-list-container {
    padding: 60px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
  .partner-card {
    height: 320px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;

    .img-block {
      width: 100%;
      height: 215px;
      background-color: #e8fafa;
      text-align: center;
      padding: 10px;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    .text-block {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 105px;

      .name {
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 5px;
        color: $main-black;
      }

      .type {
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        color: $orange;
      }
    }

    &:hover {
      .overflowed-block {
        height: 320px;
        padding: 25px;
        opacity: 1;
      }
    }

    .overflowed-block {
      position: absolute;
      height: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $orange;
      color: white;
      text-align: center;
      overflow: hidden;
      padding: 0;
      opacity: 0.5;
      width: 100%;

      transition: height 0.25s ease-in-out, padding 0.25s ease-in-out,
        opacity 0.25s ease-in-out;

      .name {
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 5px;
      }

      .type {
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 15px;
      }

      .text {
        font-size: 15px;
        line-height: 21px;
        overflow: hidden;
        max-width: 235px;
      }

      a {
        margin-top: auto;

        button {
          background-color: white;
          color: $orange;
          height: 50px;
          width: 125px;
          border-radius: 25px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;

          cursor: pointer;
          border: 2px solid transparent;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $orange;
            border: 2px solid white;
            color: white;
          }
        }
      }
    }
  }

  //media for screens less 1120px

  @media (max-width: 1120px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 16px 0 16px !important;

    .title {
      font-size: 36px;
    }

    .description {
      max-height: 280px;
      overflow-y: auto;
      font-size: 16px;
      text-align: justify;
    }

    .partners-list-container {
      padding: 40px 0;
    }

    .partner-card {
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

      .text-block {
        .name {
          font-size: 22px;
        }
      }

      .overflowed-block {
        button {
          height: 45px !important;
        }
      }
    }
  }
}
