@import './../../../scss/colors.scss';

nav[aria-label="breadcrumb"] {
  max-width: 900px;
  margin: 0px auto;
  background-color: #FFFFFF;
  ol {
    display: block;
    flex-wrap: wrap;
    list-style: none;
    // background-color: #e9ecef;
    margin: 10px 5px;
    border-radius: .25rem;
    padding: 15px !important;
    a {
        color: $orange;
    }
  }
}
