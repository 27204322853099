@import './src/scss/colors.scss';

header {
  background-color: white;
  height: 60px;
  //padding-top: 20px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: $main-black;
  position: fixed;
  width: 100%;
  z-index: 300;

  .navigation,
  .main-nav {
    height: 100%;
    position: relative;
    background-color: inherit;
  }

  .navigation {
    display: grid;
    grid-template-columns: 380px 1fr 380px;
    .main-nav {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      grid-area: 1 / 2 / 2 / 3;
      .central-block {
        width: 700px;
        height: 100%;
        min-width: 700px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        a {
          color: $main-black;
        }

        .logo-wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          .logo {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 80px;
            width: 120px;
          }
        }
      }

      .nav-item {
        height: 100%;
        position: relative;
        margin: 0px 10px 0px 10px;
        padding: 0px 10px 0px 10px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          height: 100%;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &::before {
          content: '';
          top: 0;
          transform: translateY(-120px);
          position: absolute;
          width: 100%;
          height: 60px;
          border-radius: 0 0 7.5px 7.5px;
          background-color: $orange;
          transition: all 0.2s ease-out;
        }
        &:first-child {
          &:before {
            right: 0;
          }
        }
        &:last-child {
          &::before {
            left: 0;
          }
        }
        &:hover {
          a {
            color: white;
            z-index: 2;
          }
          &::before {
            transform: translateY(0);
            z-index: 1;
          }
        }
      }
    }
  }

  .right-block {
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;

    .login-btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $main-black;
      transition: 0.1s;
      .user-icon {
        margin-right: 10px;
      }

      &:hover {
        color: $orange;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: inherit;
      }
    }

    .header-dropdown-panel {
      height: 100%;
      position: relative;
      background-color: inherit;
      .user-actions-nav {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: inherit;
        .user-name-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            color: $orange;
          }
        }
      }
      .header-dropdown-list {
        height: 0;
        padding: 0;
        border: 0 solid white;
        overflow: hidden;
        width: 125px;
        transition: all 0.2s;
        z-index: -1;
        display: block;
        position: absolute;
        background-color: #fff;
        top: 60px;
        right: 0;
        box-shadow: 0 0 20px #00000080;
        border-radius: 0 0 15px 15px;

        ul {
          height: 120px;
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          flex-direction: column;

          li {
            a {
              text-decoration: none;
              color: $main-black;
              cursor: pointer;
              &:hover {
                color: $orange;
              }
            }
          }
        }
        &.show {
          //border: 1px solid white;
          padding: 5px 20px;
          overflow: visible;
          height: auto;
        }
      }
    }

    img:first-child {
      height: 25px;
      width: auto;
      margin-right: 20px;
      margin-bottom: -5px;
    }

    .arrow {
      height: 8px;
      width: auto;
      margin-left: 5px;
      margin-bottom: -3px;
      cursor: pointer;
    }
  }

  &.transparent-mode {
    background-color: transparent;
    color: white;

    .navigation .main-nav .central-block .nav-item {
      a {
        color: white;
      }
    }

    .navigation .right-block .login-btn-wrapper {
      color: white;
      &:hover {
        color: $orange;
      }
    }
    .navigation .right-block .header-dropdown-panel .header-dropdown-list {
      background-color: transparent;
      border-top-color: transparent;
      ul {
        li {
          a {
            color: white;
            &:hover {
              color: $orange;
            }
          }
        }
      }
    }
  }

  .mediaNavbar {
    display: none;
  }

  //media for screens less 1120px

  @media (max-width: 1120px) {
    .navigation {
      display: none;
    }

    .mediaNavbar {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      &-dropDownMenu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: #ffffff;
        padding-top: 60px;
        position: absolute;
        z-index: 1999;
        transform: translateY(-120%);
        transition: transform 1s;

        & > .nav-item,
        a {
          color: #262626;
          font-size: 20px;
          margin-bottom: 25px;
        }
      }

      .dropDownMenu-open {
        transform: translateY(0);
      }

      .dropDownMenu-open-auth {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .nav-item {
          color: #262626;
          font-size: 20px;
          margin-bottom: 24px;
        }

        .nav-item:last-child {
          margin-top: 170px;
        }
      }

      &-logoAndBurger {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .logo-wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-left: 44px;

          img {
            width: 120px;
            height: 82px;
          }
        }

        .navIcon {
          width: 25px;
          height: 25px;
          position: relative;
          margin: 20px 25px 0 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.5s ease-in-out;
          -moz-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          z-index: 2000;
        }

        .navIconLightSpan {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background: #ffffff;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
        }

        .navIconDarkSpan {
          @extend .navIconLightSpan;
          background: #262626;
        }

        .navIcon .navIconLightSpan:nth-child(1) {
          top: 0px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }

        .navIcon .navIconLightSpan:nth-child(2) {
          top: 6.5px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }

        .navIcon .navIconLightSpan:nth-child(3) {
          top: 13.5px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }

        .navIcon.open .navIconLightSpan:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: -3px;
          left: 8px;
        }

        .navIcon.open .navIconLightSpan:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        .navIcon.open .navIconLightSpan:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 10px;
          left: 8px;
        }
      }
    }
  }
}

.mapHeader {

  .mediaNavbar {
    .mediaNavbar-logoAndBurger {
      align-items: center;

      .logo {
        height: 72px;
      }

      .navIcon {
        width: 24px;
        height: 24px;
        margin: 0 25px 0 0;
      }

      .navIcon.open {
        & > :first-child {
          top: -6px;
        }

        & > :last-child {
          top: 6px;
        }
      }
    }
  }
}


//media for screens with shorter height
@media (max-height: 700px) {
  .mapHeader .mediaNavbar .mediaNavbar-logoAndBurger .logo {
    height: 50px !important
  }

  .map-draw-page .map-block .extra-actions {
    top: 50px !important;
  }

  .map-draw-page .map-block .drawnOutlineWrapper {
    top: 95px !important;
  }
}
