.third-white-block {
  margin: 20px 0;
  display: grid;
  grid-auto-rows: 1fr;
  row-gap: 20px;

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  .main-image {
    width: 100%;
    border-radius: 10px;
    display: block;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .first-grid {
    height: 100%;

    .image-grid {
      order: 1
    }
    
    .image-first-order {
      order: 0
    }

    .text-grid {
      .text-container {
        background-color: #f0f0f0;
        border-radius: 10px;
        padding: 60px;
        height: 100%;
      }
    }
  }

  .btn-orange {
    padding: 0 40px;
  }


  .image-background {
    color: #FFFFFF;

    h1 {
      font-size: 60px !important;
      margin-bottom: 0;
    }

    .text-grid {
      width: 100%;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .text-container {
      background: transparent !important;
      padding: 20px !important;
      
    }

    .btn-orange {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1120px) {
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0px;
    }

    .first-grid {
      .text-grid {
        .text-container {
          padding: 20px;
        }
      }
    }

    .main-image {
      aspect-ratio: 2;
    }

    .image-first-order {
      order: 1 !important;
    }

    .image-background-overlay {
      position: relative;
    }
    .image-background-overlay::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4); /* Adjust color and transparency */
      z-index: 1;
    }
    .image-background-overlay * {
      position: relative;
      z-index: 2; /* Ensures the text appears above the overlay */
    }
  }
}